// extracted by mini-css-extract-plugin
export var bgGrey100 = "FeaturedCategories-module--bg-grey-100--ddc8c";
export var bgGrey150 = "FeaturedCategories-module--bg-grey-150--37b31";
export var bgGrey200 = "FeaturedCategories-module--bg-grey-200--dea27";
export var bgGrey300 = "FeaturedCategories-module--bg-grey-300--e007a";
export var bgGrey400 = "FeaturedCategories-module--bg-grey-400--8aa6f";
export var bgGrey500 = "FeaturedCategories-module--bg-grey-500--69710";
export var bgGrey600 = "FeaturedCategories-module--bg-grey-600--3fba9";
export var bgGrey700 = "FeaturedCategories-module--bg-grey-700--6a811";
export var bgGrey800 = "FeaturedCategories-module--bg-grey-800--24894";
export var bgGrey900 = "FeaturedCategories-module--bg-grey-900--a1ba2";
export var boxBgImage = "FeaturedCategories-module--boxBgImage--3d8fa";
export var featItemInnerBox = "FeaturedCategories-module--featItemInnerBox--e2df7";
export var textGrey100 = "FeaturedCategories-module--text-grey-100--078c9";
export var textGrey150 = "FeaturedCategories-module--text-grey-150--fd1fa";
export var textGrey200 = "FeaturedCategories-module--text-grey-200--02cee";
export var textGrey300 = "FeaturedCategories-module--text-grey-300--a4e19";
export var textGrey400 = "FeaturedCategories-module--text-grey-400--e78ec";
export var textGrey500 = "FeaturedCategories-module--text-grey-500--69633";
export var textGrey600 = "FeaturedCategories-module--text-grey-600--986f7";
export var textGrey700 = "FeaturedCategories-module--text-grey-700--c2f20";
export var textGrey800 = "FeaturedCategories-module--text-grey-800--9ce72";
export var textGrey900 = "FeaturedCategories-module--text-grey-900--85c14";