import { graphql, Link} from "gatsby"
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import classNames from "classnames"
import * as style from "./FeaturedCategories.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import { createLocalLink } from "../../utils"

export const fragment = graphql`
  fragment FeaturedCategoriesSectionFragment on WpPage_Flexlayouts_FlexibleLayouts_FeaturedCategoriesSection {
    sectionTitle
    categoryBoxes {
      bkgdImg {
        id
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 650
              placeholder: NONE
            )
          }
        }
      }
      featuredCategory {
        slug
      }
      categoryLabel
    }
  }
`

const FeaturedCategories = ({ sectionTitle, categoryBoxes }) => {

  return (
    <>
      <h2 className="sr-only">{sectionTitle}</h2>
      <Row className="my-3">
        {categoryBoxes.map((item) => {
          return (
            <Col className={categoryColumnClass} md={4} key={uuidv4()}>
              <div className="position-relative">
                <div className={style.boxBgImage}>
                  <GatsbyImage
                    image={getImage(item.bkgdImg.localFile)}
                    alt={item.bkgdImg.altText}
                  />
                </div>
                {item.featuredCategory && <div className={featItemInnerBoxClass}>
                  <p>
                    <Link
                      className={linkClass}
                      to={createLocalLink(item.featuredCategory.slug)}>
                      {item.categoryLabel}
                    </Link>
                  </p>
                </div>}
              </div>
            </Col>
          )
        })}
      </Row>
    </>
  )
}


const categoryColumnClass = classNames(
  "my-2", 
  "p-0",
  "m-lg-0" 
)

const featItemInnerBoxClass = classNames(
  "d-flex",
  "justify-content-center",
  "align-items-center",
  style.featItemInnerBox
)
const linkClass = classNames(
  "py-3",
  "px-4",
  "py-md-2",
  "px-md-3",
  "py-lg-3",
  "px-lg-4",
  "text-white",
  "text-decoration-none",
  "bg-primary"
)

export default FeaturedCategories